<template>
  <transition name="fade">
    <div>
      <stop-card v-if="stopCardVisiblity" />
      <stop-list
        v-show="isStopListVisible"
        @back="toggleStopList"
      />
    </div>
  </transition>
</template>

<script>
import stopList from '@/components/stops/stop-list'
import stopCard from '@/components/stops/stop-card'
export default {
  components: {
    stopList,
    stopCard
  },
  computed: {
    stopCardVisiblity () {
      return !!this.$store.state.stop
    },
    isStopListVisible () {
      return this.$store.state.isStopListVisible
    }
  },
  created () {
    this.$store.commit('SET', ['isStopListVisible', true])
  },
  methods: {
    toggleStopList () {
      this.$store.commit('TOGGLE', 'isStopListVisible')
    }
  }
}
</script>

<style lang="scss"></style>
