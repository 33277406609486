<template>
  <r-bottom-sheet
    v-show="isRouting"
    ref="routeBuild"
    :overlay="false"
    class="stop-card"
    initial-height="93%"
    close-button
    title="Построить маршрут"
    @closed="close"
  >
    <div class="route-build">
      <r-loader v-if="loading" />
      <div class="route-build__body">
        <div class="route-build__departure">
          <el-time-picker
            ref="time"
            v-model="departureTime"
            format="HH:mm"
            popper-class="rb-time-picker"
            prefix-icon="none"
            clear-icon="none"
            @change="checkForLoad"
            @focus="hideKeyboard"
          />
          <button class="route-build__departure-picker">
            <r-text color="caption">
              {{ `Время отправления ${dateTo24hTime(departureTime)}` }}
            </r-text>
          </button>
        </div>
        <div class="route-build__selects">
          <v-select
            v-model="valueFrom"
            :tabindex="0"
            class="vs__select-from"
            label="label"
            :reduce="label => label.value"
            :options="fromOptions"
            :value="valueFrom"
            placeholder="Откуда"
            filterable
            :clearable="false"
          >
            <template slot="no-options">
              <r-text>{{ emptyText }}</r-text>
            </template>
            <template
              slot="option"
              slot-scope="option"
            >
              <stop-option :label="option.label" />
            </template>
          </v-select>
          <rb-pin-button
            direction="from"
            @choose="chooseOnMap"
          />
          <v-select
            v-model="valueTo"
            :tabindex="1"
            class="vs__select-to"
            label="label"
            :reduce="label => label.value"
            :options="toOptions"
            :value="valueTo"
            placeholder="Куда"
            filterable
            :clearable="false"
          >
            <template slot="no-options">
              <r-text>{{ emptyText }}</r-text>
            </template>
            <template
              slot="option"
              slot-scope="option"
            >
              <stop-option :label="option.label" />
            </template>
          </v-select>
          <rb-pin-button
            direction="to"
            @choose="chooseOnMap"
          />
        </div>
        <button
          class="route-build__reverse-btn"
          @click="reverseValues"
        >
          <r-icon
            name="reverse"
            :size="24"
          />
        </button>
      </div>
      <r-error
        v-if="error"
        :error="error"
      />
      <div
        v-else
        class="route-build__footer"
      >
        <ul
          v-if="readyToBuild"
          class="route-build__route-list rb-route-list"
        >
          <r-title type="subtitle-2">
            Предложенные маршруты
          </r-title>
          <li
            v-for="(route, i) in routes"
            :key="i"
            @click="showRoute(route)"
          >
            <rb-list-item :route="route" />
          </li>
        </ul>
      </div>
    </div>
  </r-bottom-sheet>
</template>

<script>
import stopOption from './rb-select/rb-option.vue'
import rbPinButton from './rb-pin-button.vue'
import rbListItem from './rb-list-item/rb-list-item'
import 'vue-select/src/scss/vue-select.scss'
import { pointArrayToGeojson } from '@/utils/json'
import { dateTo24hTime } from '@/utils/time'

export default {
  components: {
    stopOption,
    rbPinButton,
    rbListItem
  },
  data () {
    return {
      valueFrom: '',
      valueTo: '',
      loading: false,
      routes: null,
      error: null,
      testURL: null,
      dateTo24hTime,
      departureTime: new Date(),
      identicalStops: 'Выберите разные остановки',
      requestError: 'Произошла ошибка, обратитесь к администратору',
      emptyText: 'Нет результатов',
      appointment: '',
      coordinates: {
        a: null,
        b: null
      }
    }
  },
  computed: {
    fromOptions () {
      return this.options?.filter(stop => stop.value !== this.valueTo)
    },
    toOptions () {
      return this.options?.filter(stop => stop.value !== this.valueFrom)
    },
    options () {
      return this.$store.state.stopList
        ? [
          ...this.$store.state.stopList?.features.map(item => {
            return {
              value: item.properties.id,
              label: item.properties.name
            }
          })
        ]
        : null
    },
    popup () {
      return this.$store.state.popup.from + this.$store.state.popup.to
    },
    isRouting () {
      return this.$store.state.isRouting
    },
    selectedStop () {
      return this.$store.state.selectedStop
    },
    hasChanges () {
      return this.valueFrom + this.valueTo
    },
    readyToBuild () {
      return this.valueFrom && this.valueTo
    }
  },
  watch: {
    departureTime (val) {
      if (val === null) this.departureTime = new Date()
    },
    popup () {
      this.checkSelected()
    },
    selectedStop () {
      this.setSelectedStop()
    },
    hasChanges () {
      const { valueTo, valueFrom } = this

      if (valueFrom && valueTo && valueFrom !== valueTo) {
        this.error = null
        this.checkForLoad()
      }
    }
  },
  mounted () {
    this.$refs.routeBuild.open()
    this.checkSelected()
  },
  beforeDestroy () {
    this.$store.commit('SET', ['popup.from', null])
    this.$store.commit('SET', ['popup.to', null])
  },
  methods: {
    hideKeyboard () {
      this.$refs.time.blur()
      const cancelTime = document.querySelector('.el-time-panel__btn.cancel')
      if (cancelTime) {
        cancelTime.addEventListener('click', () => {
          this.departureTime = new Date()
        })
      }
    },
    checkSelected () {
      const { from, to } = this.$store.state.popup
      if (from) this.valueFrom = from
      if (to) this.valueTo = to
    },
    checkForLoad () {
      if (this.readyToBuild) this.loadRoutes()
      this.routes = null
    },
    close () {
      this.$store.commit('RESET_STATE')
      this.$router.push('/map')
      this.departureTime = new Date()
      this.valueFrom = this.valueTo = ''
    },
    reverseValues () {
      const tempValue = this.valueFrom
      this.valueFrom = this.valueTo
      this.valueTo = tempValue
      this.checkForLoad()
    },
    async loadRoutes () {
      try {
        this.loading = true
        this.$store.commit('SET', ['routeStops', null])
        const date = this.$rDate(this.departureTime).format('iso')

        const a = await this.loadCoordinates(this.valueFrom)
        const b = await this.loadCoordinates(this.valueTo)

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url:
            this.testURL ||
            `route_pt?point_a=${a}&point_b=${b}&departure_time=${date}`
        })

        this.routes = data.map(route => ({
          type: 'FeatureCollection',
          options: route,
          features: route.legs.map(item => ({
            type: 'Feature',
            properties: {
              ...item,
              ...route,
              color:
                item.type === 'walk' ? 'rgba(46, 147, 254, 0.3)' : '#2e93fe',
              width: item.type === 'walk' ? 3 : 3,
              dash: item.type === 'walk' ? [1, 2] : [1]
            },
            geometry: {
              coordinates: item.type === 'pt' ? item.sub_route.coordinates : item.geometry.coordinates,
              type: 'LineString'
            }
          }))
        }))
        this.error = null
      } catch (e) {
        console.log(e)
        this.error = this.requestError
      } finally {
        this.loading = false
      }
    },
    chooseOnMap (appointment) {
      this.appointment = appointment
      appointment === 'to' ? (this.valueTo = null) : (this.valueFrom = null)

      this.$store.commit('START_STOP_SELECTION')
    },
    setSelectedStop () {
      if (this.selectedStop) {
        if (this.appointment === 'from') {
          if (this.selectedStop !== this.valueTo) {
            this.valueFrom = this.selectedStop
          } else {
            this.routes = null
            this.error = this.identicalStops
          }
        } else if (this.appointment === 'to') {
          if (this.selectedStop !== this.valueFrom) {
            this.valueTo = this.selectedStop
          } else {
            this.routes = null
            this.error = this.identicalStops
          }
        }
      }
      this.$store.commit('RESET_STOP_SELECTION')
    },
    showRoute (route) {
      this.loading = true

      if (route.features[2]?.properties.type === 'pt') {
        route.features[2].properties.color = '#A6CF9C'
        route.features[2].properties.width = 3
      }
      // AB Points
      const points = route.options.points.coordinates
      const abPoints = [
        {
          name: 'A',
          coordinates: points[0]
        },
        {
          name: 'B',
          coordinates: points[points.length - 1]
        }
      ]
      //
      this.$store.commit('SET', ['abPoints', pointArrayToGeojson(abPoints)])
      this.$store.commit('SET', ['flyToGeom', route])
      this.$store.commit('SET', ['userRoute', route])

      const routePtStops = route.options.legs
        .filter(i => i.type === 'pt')
        .map(i => i.stops)[0]

      if (routePtStops) {
        const routeStops = {
          type: 'FeatureCollection',
          features: routePtStops.map(item => ({
            type: 'Feature',
            properties: { ...item },
            geometry: {
              type: 'Point',
              layout: {
                'icon-allow-overlap': true,
                'icon-image': 'stp'
              },
              coordinates: [...item.geometry.coordinates]
            }
          }))
        }
        this.$store.commit('SET', ['routeStops', routeStops])
      } else {
        this.$store.commit('SET', ['routeStops', null])
      }
      this.$store.commit('TOGGLE', 'isRouting')
      this.loading = false
    },
    async loadCoordinates (id) {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `stop_point/${id}`
        })
        return data.geom.coordinates
          .reverse()
          .toString()
          .replace(',', '%2C')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 360px) {
  .vs__selected {
    font-size: 14px !important;
  }
}

.route-build {
  display: grid;
  grid-auto-flow: row;
  align-content: start;
  text-align: center;
  justify-content: stretch;
  grid-gap: 0.5rem;
  background-color: var(--bg_panel_primary);

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 1rem 0 0.5rem 0;
  }

  &__body {
    display: grid;
    grid-template-areas: 'departure departure' 'selects reverse';
    grid-template-columns: auto 2.5rem;
    width: 100%;
    grid-gap: 0.75rem 0;
    justify-content: stretch;
    justify-self: center;
    max-width: 400px;

    @media (max-width: 360px) {
      grid-gap: 0.5rem 0;
    }
  }

  &__footer {
    display: grid;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    overflow: auto;
  }

  &__button {
    height: 2.25rem;
    border: 0;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    background-color: var(--button_primary_bg);
    cursor: pointer;

    &:disabled {
      opacity: 40%;
    }
  }

  &__departure {
    display: grid;
    grid-area: departure;
    position: relative;

    .el-date-editor {
      width: 100% !important;
      .el-input__inner {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        height: 30px;
        width: 100% !important;
        padding: 0;
        z-index: 999;
      }
    }
    &-picker {
      border: 0;
      background: transparent;
      display: grid;
      width: fit-content;

      .r-text {
        border-bottom: 1px dashed var(--dividers_low_contrast);
      }
    }
  }

  &__selects {
    grid-area: selects;
    width: 100%;
    display: grid;
    position: relative;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.75rem 0;
    max-width: 400px;
    & > div {
      width: 100% !important;
    }
  }

  .rb-route-list {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
  }

  &__reverse-btn {
    box-shadow: none;
    display: block;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}
</style>
