export const formatMinutes = (mins) => {
  const rounded = Math.round(mins)

  return rounded > 60
    ? `${Math.trunc(rounded / 60)} ч ${rounded % 60} мин`
    : `${rounded} мин`
}

export const dateTo24hTime = (date) => {
  return date.toLocaleTimeString().slice(0, -3)
}

export const subtractingDates = (d1, d2) => {
  const mins = Math.round(Math.abs(new Date(d2) - new Date(d1)) / 60000)
  return mins > 60
    ? `${Math.trunc(mins / 60)} ч ${mins % 60} мин`
    : `${mins} мин`
}
