<template>
  <r-bottom-sheet
    ref="routeCard"
    :overlay="false"
    class="route-card"
    initial-height="50%"
    back-button
    close-button
    :name="route.properties.name || 'Маршрут'"
    :desc="
      routeUniqueStops.length
        ? `${routeUniqueStops.length} остановок`
        : 'Остановки не назначены'
    "
    @closed="close"
    @back="back"
  >
    <div class="route-card__body">
      <ul
        v-if="routeUniqueStops"
        class="route-card__stop-list stop-list"
      >
        <li
          v-for="stop in routeUniqueStops"
          :key="stop.stop_point.id"
          class="stop-list__item"
        >
          <r-icon
            name="stop"
            :size="18"
          />
          <div class="route-list__desc">
            <r-text> {{ stop.stop_point.name || 'Остановка' }}</r-text>
            <r-text type="caption">
              {{ `Остановка № ${stop.stop_point.no}` }}
            </r-text>
          </div>
        </li>
      </ul>
    </div>
  </r-bottom-sheet>
</template>

<script>
export default {
  data () {
    return {
      stops: []
    }
  },
  computed: {
    route () {
      return this.$store.state.route.features[0]
    },
    id () {
      return this.$store.state.route.features[0].properties.id
    },
    routeUniqueStops () {
      const dubl = {}
      return this.route.properties?.line_route_items
        ?.filter(i => i.stop_point)
        .filter(i =>
          i.stop_point.id in dubl ? false : (dubl[i.stop_point.id] = true)
        )
    }
  },
  watch: {
    id () {
      this.$store.commit('SET', ['flyToGeom', this.route.geometry])
    },
    route () {
      this.route ? this.$refs.routeCard.open() : this.$refs.routeCard.close()
    }
  },
  mounted () {
    this.$store.commit('SET', ['flyToGeom', this.route.geometry])
    this.$refs.routeCard.open()
  },
  methods: {
    close () {
      this.$store.commit('RESET_STATE')
      this.$router.push('/map')
    },
    back () {
      this.$store.commit('SET', ['isRouteListVisible', true])
      this.$store.commit('SET', ['route', null])
    }
  }
}
</script>

<style lang="scss" scoped>
.route-card {
  &__header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2rem auto 2rem;
    grid-gap: 0.5rem;
    align-items: center;
    position: relative;
    height: 56px;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &__body {
    width: 100%;
    position: relative;
    height: 100%;
  }
  .stop-list {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    &__item {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      grid-template-columns: 2rem auto;
    }
  }
}
</style>
