<template>
  <r-bottom-sheet
    ref="routeList"
    :overlay="false"
    class="route-card"
    max-height="95%"
    initial-height="93%"
    close-button
    title="Маршруты"
    @closed="close"
  >
    <div class="route-list">
      <r-search-input
        v-if="routeList"
        :filter-text="filterText"
        placeholder="Поиск"
        @change="e => filterChange(e)"
      />
      <div class="route-list-body">
        <ul>
          <route-list-item
            v-for="route in filteredList"
            :key="route.id"
            :route="route"
            @click.native="openRouteCard(route)"
          />
        </ul>
      </div>
    </div>
  </r-bottom-sheet>
</template>

<script>
import routeListItem from './route-list-item.vue'

export default {
  components: {
    routeListItem
  },
  data () {
    return {
      filterText: '',
      isRouteCardOpen: false,
      long: null,
      latt: null
    }
  },
  computed: {
    routeList () {
      return this.$store.state.routeList
    },
    activeStop () {
      return this.$store.state.stop
    },
    filteredList () {
      const filterText = this.filterText?.trim()?.toLowerCase()

      if (!filterText) return this.routeList

      return this.routeList?.filter(
        e => e.name?.toString()?.toLowerCase()?.includes(filterText)
      )
    }
  },
  mounted () {
    this.loadRouteList()
    this.$refs.routeList.open()
  },
  methods: {
    close () {
      this.$store.commit('RESET_STATE')
      this.$router.push('/map')
    },
    filterChange (e) {
      this.filterText = e
    },
    closeRouteCard () {
      this.$store.commit('SET', ['route', null])
    },
    async openRouteCard (route) {
      const targetRoute = route.line_routes[0]
      if (this.activeStop) this.$store.commit('SET', ['stop', null])
      this.$store.commit('TOGGLE', 'isRouteListVisible')
      await this.loadRouteInfo(targetRoute)

      const routeStops = {
        type: 'FeatureCollection',
        features: targetRoute?.line_route_items
          .filter(i => i.stop_point?.geom)
          .map(item => ({
            type: 'Feature',
            properties: { ...item },
            geometry: {
              type: 'Point',
              layout: {
                'icon-allow-overlap': true,
                'icon-image': 'stp'
              },
              coordinates: [...item.stop_point.geom.coordinates]
            }
          }))
      }
      this.$store.commit('SET', ['routeStops', routeStops])
    },
    async loadRouteList () {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'lines'
        })

        this.$store.commit('SET', ['routeList', Object.values(data)])
      } catch (e) {
        console.log(e)
      }
    },
    async loadRouteInfo (route) {
      try {
        if (!route?.id) return

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `line_route/${route.id}`
        })

        const routeInfo = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: { ...data },
              geometry: data.geom
            }
          ]
        }
        this.$store.commit('SET', ['route', routeInfo])
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.route-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg_panel_primary);

  &-header {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &-body {
    width: 100%;
    position: relative;
    padding-top: 1rem;
    height: 100%;
    overflow: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
  }

  &-desc {
    padding: 0.75rem 1rem;
  }
}
</style>
