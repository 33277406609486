<template>
  <r-bottom-sheet
    ref="stopList"
    :overlay="false"
    class="stop-card"
    close-button
    initial-height="93%"
    title="Остановки"
    @closed="close"
  >
    <div class="stop-list">
      <r-search-input
        v-if="stopList"
        :filter-text="filterText"
        placeholder="Поиск"
        @change="e => filterChange(e)"
      />
      <div
        v-if="stopList"
        class="stop-list__body"
      >
        <ul>
          <stop-list-item
            v-for="stopItem in filteredList"
            :key="stopItem.id"
            :stop="stopItem"
            @click.native="openStopCard(stopItem)"
          />
        </ul>
      </div>
    </div>
  </r-bottom-sheet>
</template>
<script>
import stopListItem from '@/components/stops/stop-list-item'

export default {
  components: {
    stopListItem
  },
  data () {
    return {
      filterText: ''
    }
  },
  computed: {
    stopList () {
      return this.$store.state.stopList?.features
    },
    stop () {
      return this.$store.state.stop
    },
    filteredList () {
      return this.filterText.length
        ? this.stopList.filter(
          e =>
            e.properties.name
              .toLowerCase()
              .trim()
              .indexOf(this.filterText.toLowerCase().trim()) >= 0
        )
        : this.stopList
    }
  },
  async mounted () {
    await this.$refs.stopList.open()
    if (this.stop) this.openStopCard(this.stop)
  },
  methods: {
    close () {
      this.$store.commit('RESET_STATE')
      this.$router.push('/map')
    },
    openStopCard (stop) {
      this.loadStopInfo(stop)

      this.$store.commit('SET', ['route', null])
      this.$store.commit('SET', ['flyToGeom', stop.geometry])
      this.$store.commit('TOGGLE', 'isStopListVisible')
    },
    async loadStopInfo (stop) {
      try {
        const url = `stop_point/${stop.properties?.id || stop.id}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.$store.commit('SET', ['stop', data])
      } catch (e) {
        console.log(e)
      }
    },
    filterChange (e) {
      this.filterText = e
    }
  }
}
</script>

<style lang="scss" scoped>
.stop-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg_panel_primary);

  &__header {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  &__body {
    width: 100%;
    position: relative;
    padding-top: 1rem;
    height: 100%;
    overflow-y: auto;
  }
}
</style>
