<template>
  <div class="route-simple-item">
    <div class="route-simple-item__name">
      <div class="route-simple-item__label">
        <r-icon
          name="bus"
          :size="20"
        />
        <r-text v-if="i">
          {{ i }}
        </r-text>
      </div>
      <r-title type="subtitle-2">
        {{ `${route.name}` }}
      </r-title>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true
    },
    i: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.route-simple-item {
  display: grid;

  &__label {
    background-color: var(--accent_selected);
    border-radius: var(--border_radius);
    padding: 0 0.25rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }

  &__name {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    position: relative;
    margin-left: 0.75rem;
  }
}
</style>
