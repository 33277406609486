export const encodeUrl = url => {
  const withTZ = addTimezone(url)
  return withTZ.replaceAll('+', '%2b')
}

export const addTimezone = url => {
  const sep = url.includes('?') ? '&' : '?'
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return `${url}${sep}tz=${timezone}`
}
