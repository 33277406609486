<template>
  <ul class="bottom-nav__container">
    <li
      v-for="navItem in navConfig"
      :key="navItem.route"
      class="bottom-nav__container--item"
      @click="clickHandler(navItem.route)"
    >
      <r-icon-button
        :caption="navItem.name"
        :icon="navItem.icon"
        :active="navItem.active"
      />
    </li>
  </ul>
</template>

<script>
export default {
  data () {
    return {
      navConfig: [
        {
          route: 'map',
          active: true,
          name: 'Карта',
          icon: 'map'
        },
        {
          route: 'stop-list',
          active: false,
          name: 'Остановки',
          icon: 'list'
        },
        {
          route: 'route-list',
          active: false,
          name: 'Маршруты',
          icon: 'bus'
        },
        {
          route: 'route-build',
          active: false,
          name: 'Построить',
          icon: 'route'
        },
        {
          route: 'settings',
          active: false,
          name: 'Настройки',
          icon: 'other-actions'
        }
      ]
    }
  },
  watch: {
    $route (to, _) {
      this.activeToggler(to.name)
      if (to.name === 'map') {
        this.$store.commit('RESET_STATE')
      }
    }
  },
  methods: {
    clickHandler (route) {
      if (this.$route.name === route) return
      this.$store.commit('SET', ['stop', null])
      this.$router.push(`/map/${route}`)
    },
    activeToggler (route) {
      this.navConfig.forEach(i => (i.active = false))
      const currentRoute = this.navConfig.find(i => i.route === route)
      if (!currentRoute) return
      currentRoute.active = true
    }
  }
}
</script>

<style lang="scss">
.bottom-nav__container {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  left: 0.5rem;
  display: grid;
  z-index: 500;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  justify-content: space-between;
  justify-items: stretch;
  align-items: center;
  background-color: var(--bg_surface);
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: var(--mid_shadow);

  &--item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .r-icon-button {
    justify-content: center;
    width: 100% !important;
    border-radius: 0;
  }
}
</style>
