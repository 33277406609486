<template>
  <div
    :class="[`r-title ${type} ${colorType}`]"
    :style="getStyle()"
  >
    <r-icon
      v-if="icon"
      :size="iconSize"
      :name="icon"
      :color="iconColor"
    />
    <slot />
  </div>
</template>

<script>
const titleTypes = ['title-1', 'title-2', 'subtitle-1', 'subtitle-2']

export default {
  props: {
    type: {
      validator: value => titleTypes.indexOf(value) !== -1,
      default: 'title-1'
    },
    color: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: null
    },
    colorType: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 22
    },
    iconColor: {
      type: String,
      default: null
    }
  },
  methods: {
    getStyle () {
      const styles = {}

      if (this.size) styles['font-size'] = `${this.size}px`

      if (this.color) styles.color = `${this.color} !important`

      return styles
    }
  }
}
</script>

<style lang="scss" scoped>
.r-title {
  font-size: 18px;
  font-weight: 600;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: center;
  color: var(--text_primary) !important;
  // main
  &.title-1 {
    font-size: 18px;
  }
  // modal
  &.title-2 {
    font-size: 16px;
    font-weight: 400;
    color: var(--text_subhead) !important;
  }
  // text-modal
  &.subtitle-1 {
    font-size: 14px;
  }
  // title-block
  &.subtitle-2 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    color: var(--text_subhead) !important;
  }
  &.subhead {
    color: var(--text_subhead) !important;
  }
  &.secondary {
    color: var(--text_secondary) !important;
  }
  &.primary {
    color: var(--text_primary) !important;
  }
}
</style>
