<template>
  <transition name="fade">
    <div>
      <route-card v-if="routeCardVisiblity" />
      <route-list
        v-show="isRouteListVisible"
        @back="toggleRouteList"
      />
    </div>
  </transition>
</template>

<script>
import routeList from '@/components/routes/route-list'
import routeCard from '@/components/routes/route-card'
export default {
  components: {
    routeList,
    routeCard
  },
  computed: {
    routeCardVisiblity () {
      return !!this.$store.state.route
    },
    isRouteListVisible () {
      return this.$store.state.isRouteListVisible
    }
  },
  created () {
    this.$store.commit('SET', ['isRouteListVisible', true])
  },
  methods: {
    toggleRouteList () {
      this.$store.commit('TOGGLE', 'isRouteListVisible')
    }
  }
}
</script>

<style lang="scss"></style>
