<template>
  <transition name="fade">
    <div class="route-build__wrapper">
      <route-build v-show="isRouting" />
      <button
        v-if="!isRouting"
        class="route-build__back-button"
        @click="toggleRouting"
      >
        <r-text color="white">
          Вернуться назад
        </r-text>
      </button>
    </div>
  </transition>
</template>

<script>
import RouteBuild from '@/components/route-build/route-build.vue'

export default {
  components: {
    RouteBuild
  },
  computed: {
    isRouting () {
      return this.$store.state.isRouting
    }
  },
  mounted () {
    this.$store.commit('SET', ['isRouting', true])
  },
  methods: {
    toggleRouting () {
      this.$store.commit('TOGGLE', 'isRouting')
    }
  }
}
</script>

<style lang="scss">
.route-build__back-button {
  position: fixed;
  z-index: 2;
  bottom: 3rem;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 2.25rem;
  border: 0;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background-color: var(--button_primary_bg);
  cursor: pointer;
}
</style>
