<template>
  <div :class="['rb-route-list__item', { active: showInfo }]">
    <div class="rb-route-list__item-wrapper">
      <div class="rb-route-list__item-content">
        <div class="rb-route-list__item-row">
          <r-title
            type="subtitle-1"
            class="rb-route-list__item-title"
          >
            {{ `Отправление от ${departureStop} в ${departureTime}` }}
          </r-title>

          <r-text
            type="caption"
            class="rb-route-list__item-caption"
          >
            {{ travelTime }}
          </r-text>

          <r-text
            type="caption"
            class="rb-route-list__item-caption"
          >
            {{ ` ${(route.options.distance / 1000).toFixed(1)} км` }}
          </r-text>
        </div>

        <ul class="rb-route-list__legs">
          <li
            v-for="(leg, index) in route.options.legs"
            :key="index"
            class="rb-route-list__legs-item"
          >
            <r-icon
              class="rb-route-list__legs-item-icon"
              :name="leg.type === 'walk' ? 'walking' : 'bus'"
              :size="16"
              color="var(--text_subhead)"
            />
            <r-text type="caption">
              {{ getCaption(leg) }}
            </r-text>
          </li>
        </ul>

        <div class="rb-route-list__item-row">
          <r-title
            type="subtitle-1"
            class="rb-route-list__item-title"
          >
            {{ `Прибытие в пункт назначения в ${arrivalTime}` }}
          </r-title>
        </div>
      </div>

      <button
        class="rb-route-list__button"
        @click.stop="showInfo = !showInfo"
      >
        <r-icon
          name="arrow"
          :rotate="!showInfo ? 90 : -90"
          :size="22"
          color="var(--text_subhead)"
        />
      </button>
    </div>

    <rbListItemDd
      v-if="showInfo"
      :legs="routesList"
    />
  </div>
</template>

<script>
import { formatMinutes } from '@/utils/time'
import rbListItemDd from './rb-list-item-dd'

export default {
  components: {
    rbListItemDd
  },
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      travelTime: null,
      departureTime: null,
      arrivalTime: null,
      departureStop: null,
      showInfo: false
    }
  },
  computed: {
    routesList () {
      return this.route?.options?.legs?.reduce((a, c) => {
        if (c.type !== 'pt') {
          a.push(c)
        } else {
          const length = c.stops?.length || 0
          const stops = c.stops?.map((s, i) => {
            return {
              departure_location: s.stop_name,
              departure_time: (length - 1) === i ? s.arrival_time : s.departure_time
            }
          })
          a.push(...stops)
        }
        return a
      }, [])
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const { legs } = this.route.options
      const lastIndex = legs?.length - 1
      const departure = legs[0]?.departure_time?.split('+')?.[0] || null
      const arrival = legs[lastIndex]?.arrival_time?.split('+')?.[0] || null

      this.departureStop = legs?.find(l => l.trip_name)?.departure_location

      this.departureTime = this.$rDate(departure).format('HH:mm')
      this.arrivalTime = this.$rDate(arrival).format('HH:mm')

      this.setTravelTime(departure, arrival)
    },
    setTravelTime (departure, arrival) {
      const time1 = this.$rDate(departure).format('x') / 60000
      const time2 = this.$rDate(arrival).format('x') / 60000

      this.travelTime = `${formatMinutes(time2 - time1)} в пути`
    },
    getCaption (leg) {
      const { arrival_time, departure_time } = leg
      const time1 = this.$rDate(departure_time).format('x') / 60000
      const time2 = this.$rDate(arrival_time).format('x') / 60000

      return leg.type === 'walk'
        ? formatMinutes(time2 - time1)
        : `№ ${leg.trip_name.replace('Маршрут ', '')}`
    },
    formatMinutes
  }
}
</script>

<style lang="scss">
.rb-route-list {
  &__item {
    background-color: var(--bg_containers);
    border-radius: var(--border_radius);
    padding: 0.5rem 0 0.5rem 0.5rem;

    &-wrapper {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &-content {
      display: grid;
      grid-gap: 0.5rem 0.25rem;
    }

    &-row {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      justify-content: start;
    }

    &:active,
    &.active {
      background-color: var(--accent_selected);
    }
  }

  &__legs {
    display: flex;

    &-item {
      display: flex;
      align-items: center;

      .caption {
        line-height: 1;
        text-align: center;
      }

      &::after {
        content: '';
        border: 5px solid transparent;
        border-left: 5px solid var(--button_secondary_bg);
        margin-left: 5px;
      }

      &:last-child::after {
        display: none;
      }

      &-icon {
        padding: 0 0.1rem 0 0.05rem;
      }
    }
  }

  &__button {
    display: grid;
    width: 3.5rem;
    justify-content: start;
    align-items: center;
    grid-auto-flow: row;
    border: none;
    background-color: transparent;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    height: 100%;
    transition: 0.15s;
    position: relative;

    &:focus {
      background-color: transparent;
    }
  }
}
</style>
