export const line = {
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': '#2e93fe',
    'line-width': 3
  }
}

export const marker = {
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-image': 'stp'
  }
}

export const layersConfig = {
  vehicles_clusters: {
    type: 'symbol',
    source: 'vehicles',
    // filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#cce5ff',
        10,
        '#9acafe',
        50,
        '#67b0fe',
        85,
        '#017bfe'
      ]
      // ,
      // 'circle-radius': [
      //   'step',
      //   ['get', 'point_count'],
      //   10,
      //   20,
      //   14,
      //   50,
      //   18
      // ]
    }
  },
  vehicles_clusters_count: {
    type: 'symbol',
    source: 'vehicles',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 11
    }
  },
  vehicles: {
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'vehicle',
      'icon-pitch-alignment': 'map',
      'icon-rotate': ['get', 'course'],
      'icon-rotation-alignment': 'map',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        0.25,
        24,
        0.5,
        32,
        0.8
      ],
      'icon-ignore-placement': true
    },
    paint: {
      'icon-opacity': ['match', ['to-string', ['get', 'online']], 'true', 1, 1]
    }
  }
}
