export default {
  // Main
  darkTheme: true,
  defaultPath: '/map',

  // Map
  initialState: {
    zoom: 13,
    center: [128.134904931864, 51.3765501918081],
    baseLayer: 'dark'
  },
  flyToGeom: null,

  // Routing
  popup: {
    from: null,
    to: null
  },
  isRouting: false,
  stopSelectionProcess: false,
  selectedStop: null,

  // Data
  stopList: null,
  routeList: null,
  routeStops: null,
  abPoints: null,
  userRoute: null,
  stop: null,
  route: null,

  // State
  activeRoute: null,
  isRouteListVisible: false,
  isStopListVisible: false
}
