<template>
  <div class="stop-list__item">
    <r-icon
      name="stop"
      :size="18"
    />
    <div class="stop-list__desc">
      <r-text>
        {{ stop.properties.name }}
      </r-text>
      <r-text type="caption">
        {{
          stop.properties.no ? `Остановка № ${stop.properties.no}` : 'Остановка'
        }}
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stop: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.stop-list {
  &__item {
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    cursor: pointer;
  }
  &__desc {
    padding: 0.75rem 1rem;
  }
}
</style>
