<template>
  <div :class="['notification-item', notification.status ? 'active' : '']">
    <div class="notification-item__title">
      <r-title type="subtitle-1">
        {{ notification.subject }}
      </r-title>
    </div>

    <div class="notification-item__date">
      <r-text type="caption">
        {{ formatDate(notification.updated_at) }}
      </r-text>
    </div>

    <div
      v-if="notification.open_at"
      class="notification-item__start"
    >
      {{ `Начало: ${formatDate(notification.open_at)}` }}
    </div>

    <div
      v-if="notification.close_at"
      class="notification-item__finish"
    >
      {{ `Окончание: ${formatDate(notification.close_at)}` }}
    </div>

    <p
      ref="notificationText"
      :class="['notification-item__text', { closed: !opened }]"
    >
      {{ notification.text }}
    </p>
    <div
      v-if="hasToogler"
      class="notification-item__chevron"
      @click="toggle"
    >
      <r-arrow-button :opened="opened" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      opened: true,
      hasToogler: false
    }
  },
  mounted () {
    const fullHeight = this.$refs.notificationText?.clientHeight

    if (fullHeight > 60) {
      this.opened = false
      this.hasToogler = true
    }
  },
  methods: {
    formatDate (date) {
      return new Date(date)
        .toLocaleDateString(undefined, {
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
        .split(',')
        .join(' •')
    },
    toggle () {
      this.opened = !this.opened
    }
  }
}
</script>

<style lang="scss">
.notification-item {
  background-color: var(--bg_containers);
  border-radius: var(--border_radius);
  padding: 0.5rem;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.25rem;
  grid-template-areas: 'title date' 'start start' 'finish finish' 'text text' 'chevron chevron';
  position: relative;

  &.active {
    background-color: var(--accent_selected);
  }

  &__title {
    grid-area: title;
  }

  &__date {
    text-align: right;
    grid-area: date;
    justify-self: end;
  }

  &__start {
    grid-area: start;
    color: var(--text_subhead);
    padding-top: 0.5rem;
    border-top: 1px solid var(--dividers_low_contrast);
  }

  &__finish {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
    grid-area: finish;
    color: var(--text_subhead);
  }

  &__text {
    grid-area: text;
    hyphens: auto;
    white-space: pre-line;
    margin: 0;
    color: var(--text_primary);
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;

    &.closed {
      max-height: 80px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__chevron {
    grid-area: chevron;
    justify-self: center;
    justify-content: center;
    display: flex;
    height: 20px;
    width: 100%;
  }
}
</style>
