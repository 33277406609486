<template>
  <div :class="`route-schedule-item ${active ? 'active' : ''}`">
    <div
      class="route-schedule-item__name"
      @click="active = !active"
    >
      <div class="route-schedule-item__label">
        <r-icon
          name="bus"
          :size="20"
        />
        <r-text v-if="i">
          {{ i }}
        </r-text>
      </div>
      <r-title type="subtitle-2">
        {{ `${route.name}` }}
      </r-title>
    </div>
    <div
      v-if="forecast.length"
      class="route-schedule-item__times"
    >
      <r-text
        v-for="(time, t) in forecast"
        :key="time + t"
        icon="to"
        :icon-size="16"
        color="var(--accent_success)"
        icon-color="var(--accent_success)"
      >
        {{ time }}
      </r-text>
      <r-arrow-button
        width="100%"
        arrow
        :opened="!isScheduleShow"
        @click.native="openSchedule"
      >
        {{ isScheduleShow ? 'Скрыть' : 'Расписание' }}
      </r-arrow-button>
    </div>
    <div
      v-if="!forecast.length || isScheduleShow"
      class="route-schedule-item__times"
    >
      <r-text
        v-for="(time, j) in schedule"
        :key="time + j"
        icon="to"
        :icon-size="16"
      >
        {{ time }}
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true
    },
    forecast: {
      type: Array,
      required: true
    },
    schedule: {
      type: Array,
      required: true
    },
    i: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      active: false,
      isScheduleShow: false
    }
  },
  computed: {
    stop () {
      return this.$store.state.stop
    }
  },
  methods: {
    openSchedule () {
      this.isScheduleShow = !this.isScheduleShow
    }
  }
}
</script>

<style lang="scss" scoped>
.route-schedule-item {
  display: grid;

  .r-arrow-button {
    grid-column: 1 / 4;
  }

  &__times {
    height: 0;
    display: none;
    opacity: 0;
    padding: 0.5rem 1rem;
    grid-auto-flow: row;
    grid-gap: 1rem;
    justify-content: start;
    justify-items: start;
    transition: all 0.4s ease 0.4s;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__label {
    background-color: var(--accent_selected);
    border-radius: var(--border_radius);
    padding: 0 0.25rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }

  &__name {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    position: relative;
    margin-left: 0.75rem;

    &::before {
      position: absolute;
      left: -0.75rem;
      top: 50%;
      content: '';
      width: 8px;
      height: 4px;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      background-color: var(--icons_high_contrast) !important;
    }
  }

  &.active {
    .route-schedule-item {
      &__name {
        &::before {
          transform: scale(-1);
        }
      }

      &__times {
        height: auto;
        display: grid;
        opacity: 1;
      }
    }
  }
}
</style>
