const types = {
  point: {
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'icon-image': 'stp'
    }
  },
  label: {
    type: 'symbol',
    paint: {
      'text-halo-width': 15,
      'text-halo-color': '#2e93fe',
      'text-color': '#fff'
    },
    layout: {
      'text-field': ['get', 'name'],
      'text-padding': 5,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-radial-offset': 0.5,
      'text-size': 15,
      'text-justify': 'auto'
    }
  },
  line: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#2e93fe',
      'line-width': 3
    }
  },
  combined: {
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-width': ['get', 'width'],
      'line-color': ['get', 'color']
      // 'line-dasharray': ['get', 'dash']
    }
  }
}

export const addLayer = (component, source, type) => {
  const map = component.map
  const data = component.$store.state[source]

  const layerSource = map.getSource(source)
  layerSource
    ? layerSource.setData(data)
    : map.addSource(source, {
      type: 'geojson',
      data
    })

  if (!map.getLayer(source)) {
    map.addLayer({
      id: source,
      source: source,
      ...types[type]
    })
  } else {
    map.setLayoutProperty(source, 'visibility', 'visible')
  }
}
