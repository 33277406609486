<template>
  <ul class="rb-route-list__item-dd">
    <li
      v-for="(leg, index) in legs"
      :key="index"
    >
      <r-icon
        :name="leg.type === 'walk' ? 'walking' : 'stop'"
        :size="16"
        color="var(--text_subhead)"
      />
      <r-text type="caption">
        {{ getCaption(leg) }}
      </r-text>
      <r-text type="caption">
        {{ getTime(leg) }}
      </r-text>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    legs: {
      type: Array,
      required: true
    }
  },
  methods: {
    getCaption (leg) {
      return leg.type === 'walk'
        ? leg.instructions?.find(li => li.street_name)?.street_name || '-'
        : leg.departure_location
    },
    getTime (leg) {
      const { departure_time } = leg
      const departure = departure_time?.split('+')?.[0] || null
      const time = this.$rDate(departure).format('HH:mm')

      return time
    }
  }
}
</script>

<style lang="scss">
.rb-route-list__item-dd {
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  width: 100%;

  > * {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 1rem;
    justify-content: space-between;
  }
}
</style>
