import io from 'socket.io-client'
import constants from '@/constants/url'

export default {
  install (Vue) {
    const socket = io(constants.SOCKET_URL, {
      autoConnect: false,
      path: '/ws',
      transports: ['polling']
    })
    Vue.prototype.$socket = socket
    Vue.$socket = socket
  }
}
