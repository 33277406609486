import mapboxgl from 'mapbox-gl'
import { token, baseLayers } from '@/constants/mapbox'
import { addLayer } from '../config/layers'
import { VehicleController } from '../controller/vehicle-controller'

export const initMap = async component => {
  const { state } = component.$store
  const { baseLayer, center, zoom } = state.initialState
  const style = baseLayers.find(layer => layer.name === baseLayer).link

  mapboxgl.accessToken = token
  component.map = new mapboxgl.Map({
    container: 'map',
    style,
    center,
    zoom
  })
  const map = component.map

  component.controller = new VehicleController(component)
  map.touchPitch.disable()
  map.dragRotate.disable()

  addMapControls(map, 'bottom-right')
  addLayerHandlers(map, state)

  map.once('load', async () => {
    await setCustomIcons(map)
    addLayer(component, 'stopList', 'point')
    component.controller.addVehicles()
  })

  map.on('load', () => {
    component.timer = setInterval(() => {
      component.controller.updateSource()
    }, 20000)
  })
}

const addMapControls = (map, place) => {
  const nav = new mapboxgl.NavigationControl({ showCompass: false })
  const geo = new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  })

  map.addControl(geo, place)
  map.addControl(nav, place)
}

const addLayerHandlers = (map, state) => {
  map.on('click', 'stopList', async e => {
    map.fire('closeAllPopups')
    const options = {
      closeButton: false,
      closeOnClick: true,
      className: 'stop-point__popup'
    }
    const { coordinates } = e.features[0].geometry
    const { id } = e.features[0].properties

    if (!state.stopSelectionProcess) {
      const from = window.document.createElement('button')
      from.insertAdjacentHTML('afterbegin', 'Отсюда')
      from.id = 'popup-from'

      const to = window.document.createElement('button')
      to.insertAdjacentHTML('afterbegin', 'Сюда')
      to.id = 'popup-to'

      const popupTo = new mapboxgl.Popup({ ...options, offset: [-50, 72] })
        .setLngLat(coordinates)
        .setDOMContent(from)
        .addTo(map)
      const popupFrom = new mapboxgl.Popup({ ...options, offset: [42, 72] })
        .setLngLat(coordinates)
        .setDOMContent(to)
        .addTo(map)
      map.on('closeAllPopups', () => {
        popupFrom.remove()
        popupTo.remove()
      })
    }

    toggleActiveStyling(id, map)
  })

  map.on('mouseenter', 'stopList', () => {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', 'stopList', () => {
    map.getCanvas().style.cursor = ''
  })
}

const toggleActiveStyling = (id, map) => {
  if (id) {
    const iconValue = ['case', ['==', ['get', 'id'], id], 'stp-selected', 'stp']

    map.setLayoutProperty('stopList', 'icon-image', iconValue)
  } else {
    map.setLayoutProperty('stopList', 'icon-image', 'stp')
  }
}

const setCustomIcons = map => {
  const icons = [
    {
      name: 'vehicle',
      imageUrl: require('@/assets/images/icons/vehicle.svg'),
      size: 50
    },
    {
      name: 'vehicle-selected',
      imageUrl: require('@/assets/images/icons/vehicle-selected.svg'),
      size: 50
    },
    {
      name: 'stp-selected',
      imageUrl: require('@/assets/images/icons/stp-selected.svg'),
      size: 48
    },
    {
      name: 'stp',
      imageUrl: require('@/assets/images/icons/stp.svg'),
      size: 14
    }
  ]

  return Promise.all(
    icons.map(
      ({ name, imageUrl, size }) =>
        new Promise(resolve => {
          const image = new Image(size, size)
          image.crossOrigin = 'Anonymous'
          image.style.backgroundPosition = '50%, 50%'
          image.style.backgroundSize = '100%'
          image.addEventListener('load', () => {
            map.addImage(name, image)
            resolve()
          })
          image.src = imageUrl
        })
    )
  )
}
