import Vue from 'vue'
import upperFirst from 'lodash.upperfirst'
import camelCase from 'lodash.camelcase'

const requireComponent = require.context('.', false, /r-[\w-]+\.vue$/)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .replace(/\.\w+$/, '')
    )
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})

const requireIcons = require.context(
  '@/assets/images/r-icons/?inline',
  false,
  /\.svg$/
)
const iconsBank = []

requireIcons.keys().forEach(fileName => {
  const componentName =
    'Icon' + upperFirst(camelCase(fileName.replace(/\.\w+$/, '')))
  const component = requireIcons(fileName)
  iconsBank.push(fileName.replace(/^\.\//, '').replace(/\.svg$/, ''))

  Vue.component(componentName, component.default || component)
})

export { iconsBank }
