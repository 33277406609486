let BASE_URL

switch (process.env.NODE_ENV) {
  case 'development':
    // BASE_URL = 'http://dmtlp.asspb.local'
    // BASE_URL = 'http://localhost:8081'
    // BASE_URL = 'http://10.10.8.121:8081'
    // BASE_URL = 'https://hamster.apluss.ru'
    // BASE_URL = "https://tagil.ritm3.ru";
    // BASE_URL = "http://ritm.smeu-gai.ru";
    // BASE_URL = "http://10.10.10.103";
    // BASE_URL = 'https://app.ritm3.ru'
    BASE_URL = 'https://dmtlp-mob.simetragroup.ru'
    break
  default:
    BASE_URL = ''
    break
}

export default {
  BASE_URL: BASE_URL,
  URL: `${BASE_URL}/rest`,
  SOCKET_URL: 'https://dmtlp.asspb.local'
}
