import mapboxgl from 'mapbox-gl'
import { jsonToGeojson } from '@/utils'
import { layersConfig } from '../config'

export class VehicleController {
  constructor (map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.map

    this.data = {}
    this.socketHandler = this.socketHandler.bind(this)
  }

  async loadVehicles () {
    try {
      this.data = {}

      const config = {
        include: {
          line_vehicle: { only: ['id', 'line_id'] }
        }
      }
      const url = `telemetry?config=${JSON.stringify(config)}`

      const customLineId = this.$store.state.route?.features[0]?.properties
        ?.line_id

      const { userRoute } = this.$store.state
      const { data } = await this.$store.dispatch('GET_REQUEST', { url })

      this.data = !userRoute
        ? data.reduce((a, c) => {
          const { line_vehicle, visible, current_point } = c
          if (line_vehicle?.length && visible && current_point) {
            if (line_vehicle?.find(lv => lv.line_id === customLineId) || !customLineId) {
              a.push({
                ...c,
                geom: current_point
              })
            }
          }
          return a
        }, [])
        : []
    } catch (e) {
      console.error(e)
      this.map.vehiclesLoading = false
    }
  }

  getLayerData () {
    return jsonToGeojson(Object.values(this.data))
  }

  async updateSource () {
    await this.loadVehicles()
    const data = this.getLayerData()
    const layerSource = this.mapgl.getSource('vehicles')
    if (layerSource) {
      layerSource.setData(data)
    }
  }

  async addVehicles () {
    await this.loadVehicles()
    const data = this.getLayerData()
    this.startSocketConnection()

    const layerSource = this.mapgl.getSource('vehicles')

    if (!layerSource) {
      this.mapgl.addSource('vehicles', {
        type: 'geojson',
        data
      })

      this.mapgl.addLayer({
        id: 'vehicles',
        source: 'vehicles',
        minzoom: 11,
        ...layersConfig.vehicles
      })
    } else {
      layerSource.setData(data)
    }

    this.addHandlers()
  }

  removeVehicles () {
    if (this.mapgl.getLayer('vehicles')) {
      this.mapgl.removeLayer('vehicles')
    }
    if (this.mapgl.getSource('vehicles')) {
      this.mapgl.removeSource('vehicles')
    }

    this.stopSocketConnection()
  }

  async updateVehicles () {
    const source = this.mapgl.getSource('vehicles')

    if (source) {
      await this.loadVehicles()
      const data = this.getLayerData()

      source.setData(data)
    }
  }

  socketHandler (data) {
    for (let i = 0; i < data.length; i++) {
      const track = data[i]
      const uid = parseInt(track.uid)
      const posinfo = track.posinfo

      if (!posinfo) continue

      const { lat, lon, course } = posinfo
      const current = this.data[uid]

      if (current) {
        current.geom.coordinates[0] = lon
        current.geom.coordinates[1] = lat
        current.course = course
        console.log('update track data --- ', current)
      }
    }

    const layerData = this.getLayerData()
    const source = this.mapgl.getSource('vehicles')

    if (source) {
      source.setData(layerData)
    }
  }

  startSocketConnection () {
    this.map.$socket.on(
      'track_data',
      this.socketHandler(Object.values(this.data))
    )
  }

  stopSocketConnection () {
    this.map.$socket.off('track_data', this.socketHandler)
  }

  addHandlers () {
    this.mapgl.on('mousemove', 'vehicles', e => {
      this.mapgl.getCanvas().style.cursor = 'pointer'
    })

    this.mapgl.on('mouseleave', 'vehicles', e => {
      this.mapgl.getCanvas().style.cursor = ''
    })

    this.mapgl.on('click', 'vehicles', e => {
      const { id, reg_number } = e.features[0].properties

      this.toggleActiveStyling(id)

      const options = {
        closeButton: false,
        closeOnClick: true,
        className: 'vehicle-popup'
      }
      const { coordinates } = e.features[0].geometry
      const popup = new mapboxgl.Popup({ ...options, offset: [0, -15] })
        .setLngLat(coordinates)
        .setHTML(`ГРЗ: ${reg_number}`)
        .addTo(this.mapgl)

      this.mapgl.on('closeAllPopups', () => {
        popup.remove()
      })
    })
  }

  toggleActiveStyling (id) {
    const iconDefaultValue = layersConfig.vehicles.layout['icon-image']
    const sizeDefaultValue = layersConfig.vehicles.layout['icon-size']

    if (id) {
      const iconValue = [
        'match',
        ['get', 'id'],
        id,
        'vehicle-selected',
        iconDefaultValue
      ]
      const sizeValue = ['match', ['get', 'id'], id, 0.6, 0.3]

      this.mapgl.setLayoutProperty('vehicles', 'icon-image', iconValue)
      this.mapgl.setLayoutProperty('vehicles', 'icon-size', sizeValue)
    } else {
      this.mapgl.setLayoutProperty('vehicles', 'icon-image', iconDefaultValue)
      this.mapgl.setLayoutProperty('vehicles', 'icon-size', sizeDefaultValue)
    }
  }
}
