import Vue from 'vue'
import VueRouter from 'vue-router'

import Map from '@/views/app.vue'
import Settings from '@/views/settings.vue'
import RouteList from '@/views/route-list.vue'
import RouteBuild from '@/views/route-build.vue'
import StopList from '@/views/stop-list.vue'

import store from '@/store'
const defaultPath = store.state.defaultPath

Vue.use(VueRouter)

const routes = [
  {
    path: '/map',
    component: Map,
    name: 'map',
    children: [
      {
        path: 'route-list',
        name: 'route-list',
        component: RouteList
      },
      {
        path: 'stop-list',
        name: 'stop-list',
        component: StopList
      },
      {
        path: 'route-build',
        name: 'route-build',
        component: RouteBuild
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings
      }
    ]
  },
  {
    name: 'all-routes',
    path: '*',
    redirect: defaultPath
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
