<template>
  <button
    :style="`width:${width};`"
    :class="[
      `r-arrow-button ${arrow ? 'arrow' : ''} ${
        opened && arrow ? 'opened' : ''
      }`
    ]"
  >
    <div
      class="r-button__name"
      :style="`color: ${color}`"
    >
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    arrow: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '36px'
    },
    color: {
      type: String,
      default: 'var(--text_primary)'
    },
    opened: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.r-arrow-button {
  display: grid;
  justify-content: start;
  align-items: center;
  grid-auto-flow: row;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  height: 100%;
  transition: 0.15s;
  position: relative;

  &:focus {
    background-color: transparent;
  }

  &.arrow .r-button__name::before {
    text-align: left;
    position: absolute;
    left: 0.25rem;
    top: 50%;
    content: '';
    width: 8px;
    height: 4px;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: var(--icons_high_contrast) !important;
  }

  &.opened {
    .r-button__name::before {
      transform: scale(-1);
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 600;
    color: var(--text_primary);
    grid-column: 2;
    grid-row: 1;
  }
}
</style>
