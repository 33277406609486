<template>
  <div class="vs_option">
    <r-icon
      :size="14"
      name="stop"
    />
    <r-text> {{ label }} </r-text>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.vs_option {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 0.5rem;
}
</style>
