// Main
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import socket from './socket'

// Drag, swipe & touch
import VueRecognizer from 'vue-recognizer'

// Element
import { TimePicker, Input, RadioButton, RadioGroup, Upload } from 'element-ui'
import locale from 'element-ui/lib/locale'
import lang from './local/element_local'
import 'element-ui/lib/theme-chalk/index.css'

// Globals
import '@/components/globals'

// Styles
import '@/styles/main.scss'
import '@/styles/ritm-variables.scss'

// Date formatters
import RitmDate from 'ritm-date'

const mix = {
  methods: {
    $rDate (date) {
      return new RitmDate(date)
    }
  }
}
Vue.mixin(mix)

Vue.config.productionTip = false
locale.use(lang)

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', ' ')
  }
})
// Element components
Vue.component('el-radio-group', RadioGroup)
Vue.component('el-upload', Upload)
Vue.component('el-radio-button', RadioButton)
Vue.component('el-input', Input)
Vue.component('el-time-picker', TimePicker)
Vue.component('v-select', vSelect)

Vue.use(socket)

Vue.use(VueRecognizer)

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
