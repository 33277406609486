<template>
  <div class="wrapper">
    <map-gl />
    <navbar />
    <div class="app-middle">
      <router-view />
    </div>
    <notifications />
  </div>
</template>

<script>
import mapGl from '@/components/map/map'
import navbar from '@/components/navbar/navbar'
import notifications from '@/components/notifications/notifications'

export default {
  components: {
    notifications,
    navbar,
    mapGl
  },
  created () {
    this.goToDefaultPath()
  },
  beforeRouteUpdate (_, __, next) {
    this.$store.commit('RESET_STATE')
    next()
  },
  methods: {
    goToDefaultPath () {
      if (this.$router.currentRoute.path !== this.$store.state.defaultPath) {
        this.$router.push({ path: this.$store.state.defaultPath })
      }
    }
  }
}
</script>

<style lang="scss">
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
}
</style>
