export const jsonToGeojson = (json, geom_field, type) => {
  return {
    type: 'FeatureCollection',
    features: json.map(item => ({
      type: 'Feature',
      properties: { ...item, geom: null },
      geometry: { ...item[geom_field || 'geom'], type: type || 'Point' }
    }))
  }
}

export const pointArrayToGeojson = data => {
  return {
    type: 'FeatureCollection',
    features: data.map(i => ({
      type: 'Feature',
      properties: {
        ...i
      },
      geometry: { type: 'Point', coordinates: i.coordinates }
    }))
  }
}

export const geomFromCoordinates = coordinates => {
  return {
    coordinates,
    type: 'Point'
  }
}
