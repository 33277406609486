import axios from 'axios'
import constants from '@/constants/url'
import { encodeUrl } from '@/utils'

export default {
  GET_REQUEST ({ state }, { url }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${constants.URL}/${encodeUrl(url)}`, {
          // headers
        })
        .then(data => resolve(data))
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}
