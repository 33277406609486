<template>
  <div
    id="map"
    class="map"
  >
    <r-loader v-if="loading" />
  </div>
</template>

<script>
import { initMap } from './core'
import { jsonToGeojson } from '@/utils'
import { addLayer } from './config/layers'
import extent from 'turf-extent'

export default {
  data () {
    return {
      loading: false,
      map: null,
      timer: null,
      controller: null
    }
  },
  computed: {
    baseLayer () {
      return this.$store.state.initialState.baseLayer
    },
    flyToGeom () {
      return this.$store.state.flyToGeom
    },
    padding () {
      const { stop, route } = this.$store.state
      const thirdHeight = window.screen.availHeight / 3

      return stop || route
        ? {
          bottom: thirdHeight,
          top: 40,
          left: 25,
          right: 50
        }
        : { bottom: 120, top: 30, left: 25, right: 50 }
    },
    hasActiveCard () {
      return this.userRoute || this.stop || this.route
    },
    userRoute () {
      return this.$store.state.userRoute
    },
    routeStops () {
      return this.$store.state.routeStops
    },
    route () {
      return this.$store.state.route
    },
    stop () {
      return this.$store.state.stop
    },
    stopSelectionProcess () {
      return this.$store.state.stopSelectionProcess
    }
  },
  watch: {
    hasActiveCard (v) {
      this.toggleControls(!v)
    },
    flyToGeom (geom) {
      if (!geom) return

      const bounds = extent(geom)
      const { center, zoom } = this.$store.state.initialState

      if (geom.coordinates !== center) {
        this.map.fitBounds(bounds, {
          padding: this.padding,
          maxZoom: 17,
          essential: true,
          duration: 500
        })
      } else {
        this.map.flyTo({
          center,
          zoom
        })
      }

      this.$store.commit('SET', ['flyToGeom', null])
    },
    route () {
      this.displayActiveLayer()
      this.controller.updateSource()
    },
    stop (stop) {
      if (stop) {
        if (this.$route.name === 'stop-list') return
        this.$router.push('/map/stop-list')
      } else {
        this.map.fire('closeAllPopups')
        this.map.setLayoutProperty('stopList', 'icon-image', 'stp')
      }
    },
    userRoute () {
      this.map.fire('closeAllPopups')
      this.displayActiveLayer()
      this.controller.updateSource()
    },
    routeStops () {
      this.displayActiveLayer()
    },
    baseLayer () {
      this.updateMapStyle()
    }
  },
  mounted () {
    this.initMap(this)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    toggleControls (value) {
      document.querySelector('.mapboxgl-control-container').style = `display: ${
        value ? 'block' : 'none'
      };`
    },
    async initMap () {
      this.loading = true

      await this.loadStopPoints()
      initMap(this)

      await this.map.on('style.load', () => {
        this.map.once('load', async () => {
          this.addLayerHandlers()
          this.loading = false
        })
      })
    },
    async updateMapStyle () {
      this.loading = true

      this.$store.commit('RESET_STATE')
      this.$store.commit('SET', ['stop', null])

      await initMap(this)

      await this.map.once('style.load', async () => {
        this.addLayerHandlers()
      })

      this.loading = false
    },
    async loadStopPoints () {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: 'stop_points'
        })

        const stops = jsonToGeojson(
          Object.values(data).filter(i => i.geom?.coordinates?.length),
          null,
          'Point'
        )
        this.$store.commit('SET', ['stopList', stops])
      } catch (e) {
        console.log(e)
      }
    },
    async displayActiveLayer () {
      if (this.userRoute) {
        this.hideLayer('stopList')
        addLayer(this, 'userRoute', 'combined')
        addLayer(this, 'routeStops', 'point')
        addLayer(this, 'abPoints', 'label')
        this.map.moveLayer('routeStops')
      } else {
        this.showLayer('stopList')

        if (this.map.getLayer('userRoute')) {
          this.hideLayer('userRoute')
        }

        if (this.map.getLayer('abPoints')) {
          this.hideLayer('abPoints')
        }
      }

      if (this.route) {
        this.hideLayer('stopList')
        addLayer(this, 'route', 'line')
        addLayer(this, 'routeStops', 'point')
        this.map.moveLayer('routeStops')
      } else {
        if (this.map.getLayer('route')) {
          this.hideLayer('route')
        }

        if (!this.userRoute) {
          this.showLayer('stopList')
        }
      }

      if (!this.routeStops && this.map.getLayer('routeStops')) {
        this.hideLayer('routeStops')
      }
    },
    hideLayer (layer) {
      this.map.setLayoutProperty(layer, 'visibility', 'none')
    },
    showLayer (layer) {
      this.map.setLayoutProperty(layer, 'visibility', 'visible')
    },
    async loadStopInfo (id) {
      if (!id) return
      try {
        this.loading = true
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `stop_point/${id}`
        })
        this.$store.commit('SET', ['stop', data])
        this.$store.commit('SET', ['flyToGeom', data.geom])
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async addLayerHandlers () {
      this.map.on('click', 'stopList', async e => {
        e.preventDefault()
        const stop = e.features[0]
        if (this.stopSelectionProcess) {
          this.$store.commit('SET', ['selectedStop', stop.properties.id])
          this.$store.commit('SET', ['stopSelectionProcess', false])
          this.$store.commit('SET', ['isRouting', true])
        } else {
          this.$store.commit('SET', ['isPopupVisible', true])
          await this.loadStopInfo(stop?.properties.id)
          this.popupHandler()
        }

        if (this.route) {
          this.$store.commit('SET', ['route', null])
        }
      })
      // handle click outside of layers
      this.map.on('click', e => {
        if (!e.defaultPrevented) {
          this.$store.commit('SET', ['stop', null])
          if (this.$route.name === 'map') return
          this.$router.push('/map')
        }
      })
    },
    popupHandler () {
      const from = document.getElementById('popup-from')
      const to = document.getElementById('popup-to')

      if (from && to) {
        from.addEventListener('click', () => {
          this.$store.commit('SET', ['popup.from', this.stop.id])
          this.$store.commit('SET', ['isRouting', true])
          this.$router.push('/map/route-build')
        })
        to.addEventListener('click', () => {
          this.$store.commit('SET', ['popup.to', this.stop.id])
          this.$store.commit('SET', ['isRouting', true])
          this.$router.push('/map/route-build')
        })
      }
    }
  }
}
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  overflow: hidden;
}

.stop-point__popup {
  .mapboxgl-popup-content {
    background: transparent;
    position: relative;
    box-shadow: none;

    button {
      font-size: 14px;
      color: var(--text_primary) !important;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      line-height: 1.5;
      border: none;
      background-color: var(--modal_bg);
      padding: 0.25rem 1rem 0.25rem 1.75rem;
      box-shadow: var(--soft_shadow);
      border-radius: 4px;
      z-index: 500;
      cursor: pointer;
      position: relative;

      &::before {
        width: 22px;
        height: 22px;
        position: absolute;
        top: 50%;
        margin-left: 0.25rem;
        left: 0;
        transform: translate(0, -50%);
        z-index: 600;
      }

      &:active {
        background-color: var(--accent_primary);
      }
    }
  }

  #popup-from {
    &::before {
      content: '';
      background-image: url('~@/assets/images/icons/from.png');
    }
  }

  #popup-to {
    &::before {
      content: '';
      background-image: url('~@/assets/images/icons/to.png');
    }
  }
  .mapboxgl-popup-tip {
    display: none;
  }
}

.vehicle-popup {
  .mapboxgl-popup-content {
    background-color: var(--modal_bg);
    color: var(--text_primary);
    padding: 0.5rem;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
}

.marker {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  background-image: url('~@/assets/images/icons/pin.svg');
  display: block;
}

.mapboxgl-ctrl {
  &-bottom-right {
    touch-action: manipulation;
    bottom: 50%;
    transform: translate(0, 50%);
  }

  &-group {
    background-color: var(--bg_surface);
    box-shadow: var(--soft_shadow) !important;

    button {
      width: 36px;
      height: 36px;
    }

    & button + button {
      border: 0;
    }
  }

  &-attrib {
    display: none;
  }

  &-zoom {
    &-out .mapboxgl-ctrl-icon {
      background-image: url('../../assets/images/control-icons/minus.svg') !important;
    }

    &-in .mapboxgl-ctrl-icon {
      background-image: url('../../assets/images/control-icons/add-plus.svg') !important;
    }
  }

  &-geolocate .mapboxgl-ctrl-icon {
    touch-action: manipulation;
    background-image: url('../../assets/images/control-icons/focus-zone.svg') !important;
  }

  &-geolocate-active .mapboxgl-ctrl-icon {
    background-image: url('../../assets/images/control-icons/focus-zone-active.svg') !important;
  }

  &-geolocate-background .mapboxgl-ctrl-icon {
    background-image: url('../../assets/images/control-icons/focus-zone-bg.svg') !important;
  }
}
</style>
