<template>
  <div class="route-list__item">
    <r-icon
      name="bus"
      :size="22"
    />
    <div class="route-list__desc">
      <r-text>{{ route.name || 'Маршрут' }}</r-text>
      <r-text type="caption">
        {{ extremeStops }}
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    extremeStops () {
      const nodes = this.route?.line_routes?.[0]?.line_route_items
      if (!nodes) return '—'
      const firstStop = nodes[0]?.stop_point?.name || '—'
      const secondStop = nodes[nodes.length - 1]?.stop_point?.name || '—'
      return `${firstStop} — ${secondStop}`
    }
  }
}
</script>

<style lang="scss" scoped>
.route-list {
  &__item {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    text-align: left;
    grid-auto-flow: column;
    justify-content: start;
    position: relative;
    cursor: pointer;
  }

  &__desc {
    padding: 0.75rem 0.75rem 0.75rem 0.25rem;
  }
}
</style>
