<template>
  <button
    :class="['map-choose__option', `map-choose__option-${direction}`]"
    @click="$emit('choose', direction)"
  >
    <r-icon
      :size="14"
      name="map-pin"
    />
  </button>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.map-choose__option {
  position: absolute;
  right: 0;
  height: 48px;
  padding: 0.5rem;
  border: 0;
  background-color: transparent;
  border-left: 1px solid var(--dividers_low_contrast);
  z-index: 100;
  @media (max-width: 360px) {
    padding: 0.5rem 0.25rem;
  }
  &-from {
    top: 0;
  }
  &-to {
    bottom: 0;
  }
}
</style>
