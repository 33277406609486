<template>
  <div
    v-if="error"
    class="error-message"
  >
    <r-icon
      name="alert"
      :size="22"
      color-type="error"
    />
    <r-text>
      {{ error }}
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
$error: var(--accent_error);
.error-message {
  border: 1px solid $error;
  border-radius: var(--border_radius);
  padding: 0.5rem;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: left;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  overflow: hidden;
}
</style>
