<template>
  <!-- :value="baseLayer" -->
  <v-select
    v-model="value"
    label="label"
    :reduce="layer => layer.name"
    class="vs__select-default"
    :options="layers"
    placeholder="Выберите подложку"
    :clearable="false"
    :filterable="false"
    :searchable="false"
    max-height="100px"
  >
    <template
      slot="option"
      slot-scope="option"
    >
      <r-text>{{ option.label }}</r-text>
    </template>
  </v-select>
</template>

<script>
import { baseLayers } from '@/constants/mapbox'
export default {
  data () {
    return {
      layers: [...baseLayers],
      value: ''
    }
  },
  computed: {
    baseLayer () {
      return this.$store.state.initialState.baseLayer
    }
  },
  watch: {
    value (value) {
      this.$store.commit('SET', ['initialState.baseLayer', value])
      this.$store.commit('SYNC_STORAGE')
    },
    baseLayer () {
      this.value = this.baseLayer
    }
  },
  mounted () {
    this.value = this.baseLayer
  }
}
</script>

<style lang="scss">
.base-layers-toggler {
  background-color: transparent;
  border: none;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: center;
  width: 100% !important;

  .el-input__inner {
    border: 0 !important;
  }
}
</style>
