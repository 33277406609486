export default {
  TOGGLE (state, entity) {
    state[`${entity}`] = !state[`${entity}`]
  },
  SET (state, [entity, value]) {
    if (entity.includes('.')) {
      const subEntity = entity.split('.')
      state[subEntity[0]][subEntity[1]] = value
    } else {
      state[entity] = value
    }
  },
  SYNC_STORAGE (state) {
    const initialState = JSON.stringify(state.initialState)
    localStorage.setItem('initialState', initialState)
    localStorage.setItem('darkTheme', state.darkTheme)
  },
  RESET_STOP_SELECTION (state) {
    state.selectedStop = null
    state.stopSelectionProcess = false
  },
  START_STOP_SELECTION (state) {
    const entities = ['isRouting', 'activeRoute', 'userRoute', 'stop']
    entities.forEach(e => {
      state[e] = null
    })
    state.stopSelectionProcess = true
  },
  RESET_STATE (state) {
    const entities = [
      'isRouting',
      'activeRoute',
      'userRoute',
      'route'
    ]

    entities.forEach(e => {
      if (e.includes('.')) {
        const i = e.split('.')
        state[i[0]][i[1]] = null
      } else {
        state[e] = null
      }
    })
  }
}
