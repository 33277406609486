<template>
  <el-radio-group
    v-model="theme"
    class="theme-toggler"
  >
    <el-radio-button label="dark">
      <r-icon
        class="toggler-icon"
        name="dark"
        :size="16"
      />
      Тёмная
    </el-radio-button>
    <el-radio-button label="light">
      <r-icon
        class="toggler-icon"
        name="light"
        :size="16"
      />
      Светлая
    </el-radio-button>
  </el-radio-group>
</template>

<script>
import { toggleBodyTheme } from '@/utils'

export default {
  data () {
    return {
      theme: 'dark'
    }
  },
  computed: {
    darkTheme () {
      return this.$store.state.darkTheme
    }
  },
  watch: {
    theme (theme) {
      this.changeTheme(theme)
    },
    darkTheme (v) {
      this.theme = v ? 'dark' : 'light'
    }
  },
  mounted () {
    if (!this.darkTheme) this.theme = 'light'
  },
  methods: {
    changeTheme (theme) {
      this.$store.commit('SET', ['darkTheme', theme === 'dark'])
      this.$store.commit('SET', ['initialState.baseLayer', theme])
      this.$store.commit('SYNC_STORAGE')
      toggleBodyTheme()
    }
  }
}
</script>

<style lang="scss">
.el-radio-group.theme-toggler {
  display: flex;

  .el-radio-button {
    flex: 1;

    &:focus {
      box-shadow: none !important;
    }

    .el-radio-button__inner {
      height: 36px !important;
      display: grid;
      align-items: center;
      justify-content: center;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      padding: 0;
      background-color: var(--bg_containers);
      border: 0;
      box-shadow: none !important;
      font-size: 14px;
      color: var(--text_primary);
      font-weight: 400;
    }

    &.is-active {
      .el-radio-button__inner {
        background-color: var(--accent_selected) !important;
      }
    }
  }
}
</style>
