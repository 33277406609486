<template>
  <div class="r-search-input">
    <el-input
      v-model="value"
      class="r-input"
      :placeholder="placeholderValue"
    />
    <div class="r-search-input__icons">
      <r-icon
        v-if="!isLoading"
        name="search"
        :size="20"
      />
      <em
        v-if="isLoading"
        class="el-icon-loading"
      />
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle'

export default {
  props: {
    filterText: {
      type: String,
      defalut: '',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      value: this.filterText
    }
  },
  computed: {
    placeholderValue () {
      return this.placeholder || this.$t('search')
    }
  },
  watch: {
    value (val) {
      this.updateInput(val)
    },
    number (val) {
      this.value = val
    }
  },
  methods: {
    updateInput: throttle(function (val) {
      this.$emit('change', val)
    }, 100)
  }
}
</script>

<style lang="scss">
.r-search-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .r-input {
    border-bottom: 1px solid;
    border-color: var(--dividers_low_contrast) !important;
    .el-input__inner {
      padding-left: 35px !important;
      background-color: transparent !important;
      border: none !important;
    }
  }
  &__icons {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 4px;
    color: var(--text_secondary) !important;
    i {
      font-size: 12px;
    }
  }
}
</style>

<i18n>
{
  "ru": {
    "search": "Поиск"
  },
  "en": {
    "search": "Search"
  }
}
</i18n>
