<template>
  <r-bottom-sheet
    ref="stopCard"
    :overlay="false"
    class="stop-card"
    initial-height="50%"
    back-button
    close-button
    :name="stop.name || 'Остановка'"
    desc="Ближайшие рейсы"
    @closed="close"
    @back="back"
  >
    <div
      v-if="stop.line_routes.length"
      class="stop-card__body"
    >
      <ul class="stop-card__list schedule-list">
        <li
          v-for="(route, i) in stop.line_routes"
          :key="i"
          :ref="`route_${i}`"
          class="schedule-list__item"
        >
          <route-schedule-item
            v-if="hasTimes(route.id)"
            :schedule="getSchedule(route.id)"
            :forecast="getForecast(route.id)"
            :route="route"
            :i="i + 1"
          />
          <route-simple-item
            v-else
            :route="route"
            :i="i + 1"
          />
        </li>
      </ul>
    </div>
    <r-text
      v-else
      type="caption"
    >
      Через эту остановку не проходит ни один маршрут.
    </r-text>
  </r-bottom-sheet>
</template>

<script>
import routeScheduleItem from './route-schedule-item'
import routeSimpleItem from './route-simple-item'

export default {
  components: { routeScheduleItem, routeSimpleItem },
  computed: {
    stop () {
      return this.$store.state.stop
    },
    geom () {
      return this.$store.state.stop?.geom
    }
  },
  watch: {
    geom () {
      this.$store.commit('SET', ['flyToGeom', this.geom])
    },
    stop (v) {
      const { stopCard } = this.$refs
      v ? stopCard.open() : stopCard.close()
    }
  },
  mounted () {
    this.$refs.stopCard.open()
  },
  methods: {
    hasTimes (id) {
      return this.getSchedule(id)?.length || this.getForecast(id)?.length
    },
    getSchedule (id) {
      const arrivals = this.stop.veh_journeys_times[id]?.arrivals
      return arrivals?.length ? arrivals.map(t => t.slice(0, 5)) : []
    },
    getForecast (id) {
      const arrivals = this.stop.pt_arrive_times[id]?.arrivals
      return arrivals?.length ? arrivals.map(t => t.slice(0, 5)) : []
    },
    close () {
      this.$router.push('/map')
    },
    back () {
      this.$store.commit('SET', ['isStopListVisible', true])
      this.$store.commit('SET', ['stop', null])
    }
  }
}
</script>

<style lang="scss">
.stop-card {
  &__header {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    grid-template-columns: 2rem auto 2rem;
    align-items: center;
    position: relative;
    height: 56px;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &__body {
    width: 100%;
    position: relative;
    overflow-y: auto;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    align-content: start;
    height: 100%;
  }

  &__schedule-link {
    margin-top: 1rem;
  }
}

.schedule-list {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    background-color: var(--bg_containers);
    border-radius: var(--border_radius);
    padding: 0.5rem;

    &-label {
      background-color: var(--accent_selected);
      border-radius: var(--border_radius);
      padding: 0 0.25rem;
      display: grid;
      grid-gap: 0.5rem;
      grid-auto-flow: column;
    }

    &-name {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      justify-content: start;
      position: relative;
      margin-left: 0.75rem;

      &::before {
        position: absolute;
        left: -0.75rem;
        top: 50%;
        content: '';
        width: 8px;
        height: 4px;
        clip-path: polygon(0 0, 50% 100%, 100% 0);
        background-color: var(--icons_high_contrast) !important;
      }
    }

    &-times {
      height: 0;
      display: none;
      opacity: 0;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      grid-auto-flow: row;
      grid-gap: 1.5rem;
      justify-content: start;
      transition: all 0.4s ease 0.4s;
    }

    &.active {
      .schedule-list__item {
        &-name {
          &::before {
            transform: scale(-1);
          }
        }

        &-times {
          height: auto;
          display: grid;
          opacity: 1;
        }
      }
    }
  }
}
</style>
