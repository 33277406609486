<template>
  <div class="notifications">
    <div
      :class="[
        'notifications__button-wrapper',
        activeNotifications.length ? 'has-new' : ''
      ]"
    >
      <r-icon-button
        icon="notification"
        :size="22"
        class="notifications__button"
        @click.native="open"
      />
    </div>
    <r-bottom-sheet
      ref="notificationsCard"
      :overlay="true"
      class="notifications__card"
      initial-height="93%"
      close-button
      title="Уведомления"
    >
      <el-radio-group
        v-model="notificationsTab"
        class="notifications-toggler"
      >
        <el-radio-button label="active">
          Активные
        </el-radio-button>
        <el-radio-button label="history">
          История
        </el-radio-button>
      </el-radio-group>
      <div
        v-if="notificationsTab === 'active'"
        class="notifications__list notifications__list--active"
      >
        <r-text
          v-if="!activeNotifications.length"
          type="caption"
        >
          Активные уведомления отсутствуют
        </r-text>
        <notification-item
          v-for="notification in activeNotifications"
          v-else
          :key="notification.id"
          :notification="notification"
        />
      </div>
      <div
        v-else
        class="notifications__list notifications__list--history"
      >
        <r-text
          v-if="!historyNotifications.length"
          type="caption"
        >
          Архивные уведомления отсутствуют
        </r-text>
        <notification-item
          v-for="notification in historyNotifications"
          v-else
          :key="notification.id"
          :notification="notification"
        />
      </div>
    </r-bottom-sheet>
  </div>
</template>

<script>
import notificationItem from './notification-item'

export default {
  components: { notificationItem },
  data () {
    return {
      notificationsTab: 'active',
      activeNotifications: [],
      historyNotifications: []
    }
  },
  watch: {
    $route () {
      this.$refs.notificationsCard.close()
    }
  },
  mounted () {
    this.loadNotifications()
  },
  methods: {
    async loadNotifications () {
      const url = 'notices?skip=0'
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        const sorted = data.sort((a, b) =>
          Date.parse(a.updated_at) < Date.parse(b.updated_at) ? 1 : -1
        )

        this.activeNotifications = sorted.filter(n => n.status)
        this.historyNotifications = sorted.filter(n => !n.status)
      } catch (e) {
        console.log(e)
      }
    },
    open () {
      this.$refs.notificationsCard.open()
    }
  }
}
</script>

<style lang="scss">
.notifications {
  position: relative;

  &__button {
    &-wrapper {
      position: fixed;
      right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 1rem;
      height: 36px;
      background-color: var(--bg_surface) !important;
      border-radius: var(--border_radius);
      box-shadow: var(--mid_shadow);

      &.has-new {
        &::after {
          content: '';
          right: 0.25rem;
          top: 0.25rem;
          width: 0.5rem;
          height: 0.5rem;
          position: absolute;
          background-color: var(--accent_error);
          border-radius: 50%;
        }
      }
    }
  }

  &__card {
    .r-bottom-sheet__content {
      overflow-y: hidden !important;
    }

    .el-radio-group.notifications-toggler {
      margin-bottom: 0.25rem;
      display: flex;
      z-index: 2;

      .el-radio-button {
        flex: 1;
        outline: none !important;

        &:focus {
          box-shadow: none !important;
        }

        .el-radio-button__inner {
          height: 36px !important;
          display: grid;
          align-items: center;
          justify-content: center;
          grid-auto-flow: column;
          grid-gap: 0.5rem;
          padding: 0;
          background-color: var(--bg_containers);
          border: 0;
          box-shadow: none !important;
          font-size: 14px;
          color: var(--text_primary);
          font-weight: 400;
        }

        &.is-active {
          .el-radio-button__inner {
            background-color: var(--accent_selected) !important;
          }
        }
      }
    }
  }

  &__list {
    justify-content: stretch;
    padding: 0.5rem 0;
    border-radius: var(--border_radius);
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    overflow-y: scroll;
    align-content: start;
    height: 95%;
    padding-bottom: 0.5rem;
  }
}
</style>
