import Vue from 'vue'
import Vuex from 'vuex'

import actions from './main/actions'
import mutations from './main/mutations'
import state from './main/state'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions
})
