<template>
  <dl class="r-settings-list">
    <div
      v-for="{ id, component, title } in settings"
      :key="id"
      class="r-settings-list__item"
    >
      <dt class="r-settings-list__item--title">
        {{ title }}
      </dt>
      <dd class="r-settings-list__item--core">
        <component :is="component" />
      </dd>
    </div>
  </dl>
</template>

<script>
import themeToggler from '@/components/settings/theme-toggler'
import baseLayerToggler from '@/components/settings/baselayers-toggler.vue'

export default {
  components: {
    themeToggler,
    baseLayerToggler
  },
  props: {
    settings: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss">
.r-settings-list {
  width: 100%;
  display: grid;
  justify-content: stretch;
  grid-auto-flow: row;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-auto-flow: row;
    padding-bottom: 1rem;
    grid-gap: 0.5rem;

    &--title {
      font-weight: 400;
      line-height: 1.5;
      font-size: 12px;
      color: var(--text_secondary);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }
}
</style>
